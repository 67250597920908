import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { ToastType } from 'react-toastify';
import validate from 'validate.js';

import type { ILogInResponse } from '@feathr/blackbox';
import { isTokenValid, Session } from '@feathr/blackbox';
import { Alert, AlertType, Button, ButtonValid, Form, Input, toast } from '@feathr/components';
import { flattenErrors, useDebounce } from '@feathr/hooks';
import { isWretchError, wretch } from '@feathr/rachis';

import Page from '../../Components/Page';

import * as styles from './PasswordPage.css';

export interface IToken {
  account_user_id?: string;
  exp?: number;
  nbf?: number;
  user_id?: string;
}

interface IProps {
  description: ReactNode;
  errorMessage: string;
  invalidMessage: ReactNode;
  submitText: string;
  successRedirect: string;
  title: string;
  token: string;
}

function ResetPasswordPage({
  description,
  errorMessage,
  invalidMessage,
  submitText,
  successRedirect,
  title,
  token,
}: IProps): JSX.Element {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidate, setPasswordValidate] = useDebounce<boolean>(false);
  const [confirmPasswordValidate, setConfirmPasswordValidate] = useDebounce<boolean>(false);

  const loginButton = (
    <Button key={'login'} link={'/'} type={'link'}>
      Return to log in
    </Button>
  );

  // Check for valid token.
  if (!isTokenValid<IToken>(token)) {
    return (
      <Page title={title}>
        <Alert className={styles.alert} type={AlertType.danger}>
          {invalidMessage}
        </Alert>
        {loginButton}
      </Page>
    );
  }

  function handlePasswordChange(newValue?: string): void {
    setPasswordValidate(true);
    setPassword(newValue ?? '');
  }

  function handleConfirmPasswordChange(newValue?: string): void {
    setConfirmPasswordValidate(true);
    setConfirmPassword(newValue ?? '');
  }

  async function handleSubmit(): Promise<void> {
    const response = await wretch<ILogInResponse>(`${BLACKBOX_URL}reset-password/${token}`, {
      method: 'POST',
      body: JSON.stringify({ password, password2: confirmPassword }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (isWretchError(response)) {
      toast(errorMessage, { type: ToastType.ERROR });
      // eslint-disable-next-line no-console
      console.error(response.error.message);
      throw new Error('Something went wrong.');
    }
    Session.token = response.data.token;

    const appUrl =
      process.env.NODE_ENV === 'production' ? `//${EXTENDER_URL}/` : '//local.feathr.app:9001/';
    window.location.assign(`${appUrl}${successRedirect}`);
  }

  function validateForm(): Record<'password' | 'confirmPassword', string[]> {
    return (
      validate(
        { password, confirmPassword },
        {
          password: {
            format: {
              pattern: /(?!^\d+$)^.+$/,
              message: 'must contain a letter or special character',
            },
            length: { minimum: 15 },
            presence: { allowEmpty: false, message: 'may not be empty' },
          },
          confirmPassword: {
            equality: 'password',
          },
        },
        { format: 'grouped' },
      ) || {}
    );
  }

  const errors = validateForm();

  return (
    <Page description={description} title={title}>
      <Form
        actions={[
          <ButtonValid
            errors={flattenErrors(errors)}
            key={'signin'}
            onClick={handleSubmit}
            type={'primary'}
          >
            {submitText}
          </ButtonValid>,
          loginButton,
        ]}
        label={title}
      >
        <Input
          helpPlacement={'bottom'}
          helpText={
            'Must be at least 15 characters in length and contain at least one letter or special character.'
          }
          label={'Enter a new password'}
          onChange={handlePasswordChange}
          type={'password'}
          validationError={passwordValidate && errors.password ? errors.password : undefined}
          value={password}
        />
        <Input
          label={'Confirm your new password'}
          onChange={handleConfirmPasswordChange}
          type={'password'}
          validationError={
            confirmPasswordValidate && errors.confirmPassword ? errors.confirmPassword : undefined
          }
          value={confirmPassword}
        />
      </Form>
    </Page>
  );
}

export default ResetPasswordPage;
