import React from 'react';

import { Accounts, Users } from '@feathr/blackbox';

export { Session } from '@feathr/blackbox';

export class RootStore {
  public Accounts = new Accounts([]);

  public Users = new Users([]);
}

export function createStore() {
  return new RootStore();
}

export const StoresContext = React.createContext(new RootStore());

export default createStore;
