import React from 'react';

import { Button } from '@feathr/components';
import { getQuery } from '@feathr/hooks';

import PasswordPage from '../../Components/PasswordPage';

function ResetPasswordPage() {
  const { t: token } = getQuery<{ t: string }>();

  // Extract user id from token.
  return (
    <PasswordPage
      description={<p>Welcome back. Reset your password below.</p>}
      errorMessage={'Your password could not be reset. Please try again'}
      invalidMessage={
        <>
          Sorry. Your password reset link has expired, or has already been used. Get{' '}
          <Button link={'/forgot-password'} type={'link'}>
            new instructions
          </Button>{' '}
          on how to reset your password.
        </>
      }
      submitText={'Save'}
      successRedirect={'projects?message=passwordReset'}
      title={'Reset Your Password'}
      token={token}
    />
  );
}

export default ResetPasswordPage;
