import type { JSX } from 'react';
import React, { useState } from 'react';
import { ToastType } from 'react-toastify';
import validate from 'validate.js';

import { Alert, AlertType, Button, ButtonValid, Form, Input, toast } from '@feathr/components';
import { flattenErrors, useDebounce } from '@feathr/hooks';
import type { IRachisMessage } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';

import Page from '../../Components/Page';

import * as styles from './ForgotPasswordPage.css';

function ForgotPasswordPage(): JSX.Element {
  const [email, setEmail] = useState('');
  const [emailValidate, setEmailValidate] = useDebounce<boolean>(false);
  const [sent, setSent] = useState(false);

  const title = 'Having trouble signing in?';
  const loginButton = (
    <Button key={'login'} link={'/'} type={'link'}>
      Return to log in
    </Button>
  );

  if (sent) {
    return (
      <Page title={title}>
        <Alert className={styles.alert} type={AlertType.success}>
          Instructions on how to reset your password have been sent to your email address. It should
          arrive in the next few minutes.
        </Alert>
        {loginButton}
      </Page>
    );
  }

  function handleEmailChange(newValue?: string) {
    setEmailValidate(true);
    setEmail(newValue ?? '');
  }

  async function handleSend(): Promise<void> {
    const response = await wretch<IRachisMessage>(`${BLACKBOX_URL}reset-password`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (isWretchError(response)) {
      toast('You were unable to reset your password. Check your email and try again.', {
        type: ToastType.ERROR,
      });
      // eslint-disable-next-line no-console
      console.error(response.error.message);
      return;
    }
    setSent(true);
  }

  function validateForm(): Record<'email', string[]> {
    return (
      validate(
        { email },
        { email: { presence: { allowEmpty: false, message: '^Enter an email' } } },
        { format: 'grouped' },
      ) || {}
    );
  }

  const errors = validateForm();

  return (
    <Page
      description={
        <p>
          You’ve come to the right place to reset a forgotten password. We will email you
          instructions on how to reset your password.
        </p>
      }
      title={title}
    >
      <Form
        actions={[
          <ButtonValid
            errors={flattenErrors(errors)}
            key={'signin'}
            onClick={handleSend}
            type={'primary'}
          >
            Send me instructions
          </ButtonValid>,
          loginButton,
        ]}
        label={'Reset your password'}
      >
        <Input
          label={'Email'}
          onChange={handleEmailChange}
          type={'text'}
          validationError={emailValidate && errors.email ? errors.email : undefined}
          value={email}
        />
      </Form>
    </Page>
  );
}

export default ForgotPasswordPage;
