import type { JSX } from 'react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotPasswordPage from '../../App/ForgotPasswordPage';
import InvitePage from '../../App/InvitePage';
import LoginPage from '../../App/LoginPage';
import ResetPasswordPage from '../../App/ResetPasswordPage';
import OTPPage from '../OTPPage';
import NotFound from './NotFound';
import RedirectTo404 from './RedirectTo404';

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route exact={true} path={'/'}>
        <LoginPage />
      </Route>
      <Route exact={true} path={'/one-time-password'}>
        <OTPPage />
      </Route>
      <Route path={'/404-not-found'}>
        <NotFound />
      </Route>
      <Route path={'/forgot-password'}>
        <ForgotPasswordPage />
      </Route>
      <Route path={'/invite'}>
        <InvitePage />
      </Route>
      <Route path={'/reset-password'}>
        <ResetPasswordPage />
      </Route>
      <RedirectTo404 />
    </Switch>
  );
}

export default Routes;
