/* eslint-disable simple-import-sort/imports */
import React, { Suspense } from 'react';

import { Session } from '@feathr/blackbox';
import Routes from './Routes';

import '@mantine/core/styles.css';
import '@feathr/components/dist/style/theme/index.css';
import '@feathr/components/dist/style/global/index.css';
import './App.css';

// Load after theme/index.css.
import { ErrorBoundary, Spinner, toast, ToastContainer } from '@feathr/components';
import { getQuery } from '@feathr/hooks';

export function redirect(): void {
  const { from, next, return_to } = getQuery<{ from: string; next: string; return_to: string }>();
  const loginUrl = process.env.NODE_ENV === 'production' ? LOGIN_URL : 'local.feathr.app:9005';
  const extenderUrl =
    process.env.NODE_ENV === 'production' ? EXTENDER_URL : 'local.feathr.app:9001';
  if (next) {
    window.location.assign(`//${loginUrl}/skilljar/?next=${next}`);
  } else if (return_to) {
    window.location.assign(`//${loginUrl}/zendesk/?return_to=${return_to}`);
  } else {
    window.location.assign(from || `//${extenderUrl}`);
  }
}

function App() {
  if (Session.isLoggedIn) {
    // TODO: Fix missing toast styles
    toast('You are already logged in.', { type: 'warning' });
    redirect();
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <Routes />
        <ToastContainer />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
