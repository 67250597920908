import { ObjectId } from 'bson';
import { computed, makeObservable, toJS } from 'mobx';

import { concatPath, moment, TimeFormat } from '@feathr/hooks';
import type { Languages } from '@feathr/locales';
import type { DeepObservable, IBaseAttributes, IRachisMessage, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { TCampaignGroup } from './campaigns';
import { CampaignClass } from './campaigns';
import type { TFlagsRecord } from './flags';
import type { IConfig } from './redirects';
import { EUserRoleIDs, type IUserRole } from './user_roles';

const reportsClassNames = [...Object.values(CampaignClass), 'Flight', 'Event'] as const;
export type TReportClass = (typeof reportsClassNames)[number];

export interface IUserSettings {
  displayed_campaign_columns?: Record<TCampaignGroup, string[]>;
  displayed_campaign_columns_global?: Record<TCampaignGroup, string[]>;
  persons_column_ids?: string[];
  favorite_account_ids?: string[];
  invite_partners_column_ids?: string[];
  partners_column_ids?: string[];
  segments_column_ids?: string[];
  reports_config?: Record<TReportClass, IConfig>;
  lng?: Languages;
}

export interface IUser extends IBaseAttributes {
  account: string;
  calendly_link?: string;
  confirmed: boolean;
  date_last_access: string;
  date_last_modified: string;
  email: string;
  flags: TFlagsRecord;
  /**
   * If this field is populated, the referenced UserRole will apply
   * to the user across all accounts. Only used for feathren as of now.
   */
  global_role?: string;
  last_nps: number | null;
  login_attempts: number;
  /** Total number of logins  */
  logins: number;
  name?: string;
  picture?: string;
  recent_account_ids: string[];
  /**
   * Populated for users who either have global_role or are on the
   * currently logged in account's user_roles list. This lets us
   * avoid checking the account for the user's current permissions.
   */
  role?: IUserRole;
  settings: IUserSettings;
  skilljar_id: string;
  timezone: string;
  username?: string;
  email_opt_out?: boolean;
}

export class User extends DisplayModel<IUser> {
  public readonly className = 'User';

  public get constraints(): TConstraints<IUser> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      timezone: {
        presence: {
          allowEmpty: false,
        },
      },
      calendly_link: {
        url: {
          allowLocal: false,
          message: '^Please enter a valid URL for your Calendly link',
        },
      },
    };
  }

  constructor(attributes: Partial<IUser> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    const currentUserId = '';
    if (this.id === currentUserId) {
      return concatPath(`/settings/user/profile`, pathSuffix);
    }
    return concatPath(`/settings/account/users/${this.id}`, pathSuffix);
  }

  public getDefaults(): Partial<IUser> {
    return {
      recent_account_ids: [],
    };
  }

  /**
   * Get reports_config setting - maps report classes to report configs
   */
  public getReportsConfigSetting(
    reportClass: TReportClass,
    initialConfig: IConfig,
  ): Record<TReportClass, IConfig> {
    return this.getSetting('reports_config', {
      [reportClass]: initialConfig,
    } as Record<TReportClass, IConfig>);
  }

  /**
   * Set reports_config setting (forcing settings attribute to be dirty)
   */
  public setReportsConfigSetting(config: Record<TReportClass, IConfig>): void {
    this.setSetting('reports_config', config);
    this.setAttributeDirty('settings');
  }

  public getSetting<K extends keyof IUserSettings & string>(
    attribute: K,
  ): DeepObservable<IUserSettings[K]>;

  public getSetting<K extends keyof IUserSettings & string>(
    attribute: K,
    defaultValue: Exclude<IUserSettings[K], undefined>,
  ): DeepObservable<Exclude<IUserSettings[K], undefined>>;

  public getSetting<K extends keyof IUserSettings & string>(
    key: K,
    defaultValue?: unknown,
  ): unknown {
    const settings: IUserSettings = this.get('settings', {});
    return settings[key] !== undefined && settings[key] !== null ? settings[key] : defaultValue;
  }

  public setSetting<K extends keyof IUserSettings & string>(key: K, value: IUserSettings[K]): void {
    const settings: IUserSettings = this.get('settings', {});
    this.set({ settings: { ...settings, [key]: value } });
  }

  public get flags(): TFlagsRecord {
    /*
     * This getter exists because we only want to consider the truthy keys to override
     * account flags. User flags should be able to set a flag to true, not set it back
     * to false (turn off a feature) if it is already on at the account level.
     */
    if (!this.get('flags')) {
      return {};
    }
    return Object.fromEntries(
      Object.entries(toJS(this.get('flags'))).filter(([, value]) => {
        return value as boolean;
      }),
    );
  }

  @computed
  public get dateCreated(): string {
    return moment.utc(new ObjectId(this.id).getTimestamp()).format(TimeFormat.isoDateTime);
  }

  @computed
  public get isSudoer(): boolean {
    return this.get('role')?.sudo?.mode === 'all';
  }

  @computed
  public get isSuperuser(): boolean {
    return this.get('role')?.id === EUserRoleIDs.SuperUser;
  }

  @computed
  public get isInternalOnboarding(): boolean {
    return this.get('role')?.id === EUserRoleIDs.InternalOnboarding;
  }

  @computed
  public get isAdmin(): boolean {
    return this.get('role')?.id === EUserRoleIDs.Admin;
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed User';
  }

  @computed
  public get role(): string {
    return this.get('role')?.id || EUserRoleIDs.User;
  }

  /**
   * Forcefully resend an email invite to the user.
   */
  public async resendInvite(): Promise<IRachisMessage> {
    this.assertCollection(this.collection);

    const response = await wretch<IRachisMessage>(this.collection.url('invite'), {
      method: 'POST',
      body: JSON.stringify({ email: this.get('email'), role: this.role, force: true }),
      headers: this.collection.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  /**
   * Send a password reset link to the user.
   */
  public async sendPasswordResetLink(): Promise<IRachisMessage> {
    this.assertCollection(this.collection);

    const response = await wretch<IRachisMessage>(this.collection.url('reset-password'), {
      method: 'POST',
      body: JSON.stringify({ email: this.get('email') }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}

export class Users extends Collection<User> {
  public getModel(attributes: Partial<IUser>): User {
    return new User(attributes);
  }

  public getClassName(): string {
    return 'users';
  }

  public url(): string;

  public url(variant: 'invite' | 'reset-password'): string;

  public url(variant?: 'invite' | 'reset-password'): string {
    switch (variant) {
      case 'invite':
        return `${this.getHostname()}invite`;

      case 'reset-password':
        return `${this.getHostname()}reset-password`;

      default:
        return super.url();
    }
  }

  public async invite({
    emailList,
    role,
  }: {
    emailList: string[];
    role: string;
  }): Promise<IRachisMessage> {
    const response = await wretch<IRachisMessage>(this.url('invite'), {
      method: 'POST',
      body: JSON.stringify({ users: emailList.map((email) => ({ email, role })) }),
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}
