import { createTheme, MantineProvider } from '@mantine/core';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { i18n } from '@feathr/locales';

import createStore, { StoresContext } from '../state';

interface IProps {
  children: ReactNode;
}

function AppWrapper({ children }: IProps): JSX.Element {
  const store = createStore();
  const theme = createTheme({});

  return (
    <I18nextProvider i18n={i18n}>
      <StoresContext.Provider value={store}>
        <BrowserRouter>
          <MantineProvider theme={theme}>{children}</MantineProvider>
        </BrowserRouter>
      </StoresContext.Provider>
    </I18nextProvider>
  );
}

export default AppWrapper;
