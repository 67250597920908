import type { JSX, ReactNode } from 'react';
import React from 'react';

import * as styles from './Page.css';

import logo from '@feathr/extender/images/feathr-logo.png';

interface IPageProps {
  children: ReactNode;
  description?: React.ReactNode;
  footer?: ReactNode;
  title?: ReactNode;
}

function Page({ children, description, footer, title }: IPageProps): JSX.Element {
  return (
    <main className={styles.root}>
      <img alt={'Feathr'} className={styles.logo} src={logo} />
      <div className={styles.wrapper}>
        {(!!title || !!description) && (
          <div className={styles.header}>
            {!!title && <h1 className={styles.title}>{title}</h1>}
            {!!description && <div className={styles.description}>{description}</div>}
          </div>
        )}
        {children}
      </div>
      {!!footer && <div className={styles.footer}>{footer}</div>}
    </main>
  );
}

export default Page;
