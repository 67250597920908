import type { JSX } from 'react';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

function RedirectTo404(): JSX.Element {
  const location = useLocation();
  return <Redirect to={{ pathname: '/404-not-found', state: { from: location } }} />;
}

export default RedirectTo404;
