import type { JSX } from 'react';
import React from 'react';
import { useLocation } from 'react-router';

import Page from '../../../Components/Page';

interface ILocationState {
  from: {
    pathname: string;
  };
}

function NotFound(): JSX.Element {
  const {
    state: { from },
  } = useLocation<ILocationState>();
  return (
    <Page title={"Sorry! This page doesn't exist."}>
      <p>
        You were trying to visit <em>{from.pathname}</em>.
      </p>
    </Page>
  );
}

export default NotFound;
