/* eslint-disable simple-import-sort/imports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'normalize.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { ErrorBoundary } from '@feathr/components';
import { Collection } from '@feathr/rachis';

import App from './App';
import AppWrapper from './AppWrapper';

Collection.prototype.getHostname = (): string => BLACKBOX_URL;

const root = ReactDOM.createRoot(document.getElementById('react-root')!);
root.render(
  <AppWrapper>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </AppWrapper>,
);
