import React from 'react';

import { Button } from '@feathr/components';
import { getQuery } from '@feathr/hooks';

import PasswordPage from '../../Components/PasswordPage';

function InvitePage() {
  const { t: token } = getQuery<{ t: string }>();

  return (
    <PasswordPage
      description={<p>Welcome to Feathr! Please provide a password for your account.</p>}
      errorMessage={'Your account could not be created.'}
      invalidMessage={
        <>
          Sorry. Your invitation link has expired, or has already been used. Return to the{' '}
          <Button link={'/'} type={'link'}>
            sign in
          </Button>{' '}
          page.
        </>
      }
      submitText={'Create account'}
      successRedirect={'settings/user/profile?message=invite'}
      title={'New User'}
      token={token}
    />
  );
}

export default InvitePage;
